import React from "react"

import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import BlogPostSnippet from "../../templates/BlogPostSnippet"
import { useInView } from "react-intersection-observer"

const BlogList = () => {
  // const blogData = useStaticQuery(graphql`
  //   query {
  //     allContentfulCafeKrakenBlog {
  //       edges {
  //         node {
  //           author {
  //             lastName
  //             firstName
  //           }
  //           slug
  //           snippet
  //           title
  //           publishDate(formatString: "Do MMMM y")
  //           heroImage {
  //             gatsbyImageData
  //           }
  //         }
  //       }
  //     }
  //   }
  // `).allContentfulCafeKrakenBlog.edges

  const { ref, inView } = useInView()

  return (
    <section
      className="blog--list pb-5"
      // style={{
      //   transition: "opacity 2000ms, transform 2000ms",
      //   opacity: inView ? 1 : 0,
      //   transform: `translateY(${inView ? 0 : 100}px)`,
      //   transition: "all 2s",
      // }}
      ref={ref}
    >
      <div className="container">
        <p className="text-center pb-5">Coming Soon!</p>

        {/* {blogData &&
          blogData.map(blog => (
            <div className="col-12 col-lg-4">
              <BlogPostSnippet
                Slug={`/blog/${blog.node.slug}`}
                CoverImage={blog.node.heroImage.gatsbyImageData}
                Title={blog.node.title}
                PublishDate={blog.node.publishDate}
                Author={`${blog.node.author.firstName} ${blog.node.author.lastName}`}
                Snippet={blog.node.snippet}
                Page="blog"
              />
            </div>
          ))} */}
      </div>
    </section>
  )
}

export default BlogList
