import React from "react"

import BlogHero from "../components/Blog/BlogHero"
import BlogList from "../components/Blog/BlogList"

import MainLayout from "../layouts/MainLayout"
import { Helmet } from "react-helmet"

const Blog = () => {
  return (
    <MainLayout>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="C.Liu" />

        <title>Blog | Café Kraken</title>
        <meta
          name="description"
          content="Stay up to date with all our latest news and info."
        />
      </Helmet>
      <section className="Blog">
        <BlogHero></BlogHero>
        <BlogList></BlogList>
      </section>
    </MainLayout>
  )
}

export default Blog
